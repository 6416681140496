import React, { PureComponent } from "react";
// import map from 'lodash/map';
import { connect } from "react-redux";
import { Card, Container, Row } from "reactstrap";
import { css } from "@emotion/core";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Popover from "@material-ui/core/Popover";
import PulseLoader from "react-spinners/PulseLoader";

am4core.useTheme(am4themes_animated);

const override = css`
  text-align: center;
  display: block;
  margin: 0 auto;
  margin-top: 250px;
  border-color: green;
`;
class Overview extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      setAnchorEl: null,
    };
  }
  totalIdleTime = (event) => {
    this.setState({
      setAnchorEl: event.currentTarget,
    });
  };

  productiveTime = (event) => {
    this.setState({
      setAnchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      setAnchorEl: null,
    });
  };
  componentDidMount() {
    var chart = am4core.create("overview_top_projects", am4charts.PieChart);

    // Add data
    chart.data = [
      {
        country: "Testing",
        litres: 24.18,
        color: "#e63045",
      },
      {
        country: "BFL",
        litres: 0.28,
        color: "#3dc26c",
      },
    ];

    // Add and configure Series
    var pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "litres";
    pieSeries.dataFields.category = "country";
    pieSeries.innerRadius = am4core.percent(50);
    pieSeries.ticks.template.disabled = true;
    pieSeries.labels.template.disabled = true;

    pieSeries.colors.list = [
      am4core.color("green"),
      am4core.color("red"),
      am4core.color("#FF6F91"),
      am4core.color("#FF9671"),
      am4core.color("#FFC75F"),
      am4core.color("#F9F871"),
    ];
  }
  render() {
    const open = Boolean(this.state.setAnchorEl);
    const id = open ? "simple-popover" : undefined;

    let memberLoading = this.props?.memberLoading;
    let totalHours = this.props?.timeData?.totalHours;
    let totalMinutes = this.props?.timeData?.totalMinutes;

    let idleHours = this.props?.timeData?.idleHours;
    let idleMinutes = this.props?.timeData?.idleMinutes;

    let productiveHours = this.props?.timeData?.productiveHours;
    let productiveMinutes = this.props?.timeData?.productiveMinutes;

    let totalHoursToMinutes = totalHours * 60 + totalMinutes;
    let idleHoursToMinutes = idleHours * 60 + idleMinutes;
    let productiveHoursToMinutes = productiveHours * 60 + productiveMinutes;
    let productiveHoursPercent = 0;
    let idleHoursPercent = 0;
    if (productiveHoursToMinutes !== 0) {
      productiveHoursPercent =
        (productiveHoursToMinutes / totalHoursToMinutes) * 100;
    }
    if (idleHoursToMinutes !== 0) {
      idleHoursPercent = (idleHoursToMinutes / totalHoursToMinutes) * 100;
    }

    return (
      <>
        {/* {(window.location.pathname !== "/dashboard-individual" ||
          (this?.props?.profile?.role === "Member" &&
            window.location.pathname === "/dashboard-individual")) && (
          <div
            className="header pb-5 pt-md-6"
            style={{ paddingTop: "5rem!important" }}
          ></div>
        )} */}

        <Container fluid>
          <div className="header-body mt-2">
            {/* <Row> */}
            {/* <div className="col header-dashboard"> */}
            <Card className="header pt-md-12 shadow chart-card">
              {/* <div style={{ height: `auto` }} className="p--1"> */}
              <Row className="card-min-hight">
                {/* Total time Tracked */}
                <div className="col-lg-3 col-md-12 col-sm-4 col-xs-12 header-dashboard-section py-3">
                  {memberLoading ? 
                  <div className="time-track-section">
                    <PulseLoader color="#36d7b7" />
                  </div> :
                  this.props.timeData === {} ? (
                    this.props?.companyList?.length === 0 ? (
                      <>
                        <span className="headline">Total time tracked</span>
                        <h1>0h 0m</h1>
                      </>
                    ) : (
                      <div className="time-track-section">
                        <PulseLoader color="#36d7b7" />
                      </div>
                    )
                  ) : (
                    <>
                      <span className="headline">Total time tracked</span>
                      {this.props.timeData === undefined ? (
                        <h1>0h 0m</h1>
                      ) : isNaN(totalHours) ? (
                        // <div className="time-track-section">
                        //   <PulseLoader color="#36d7b7" />
                        // </div>
                          <h1>0h 0m</h1>
                      ) : (
                        <h1>
                          {totalHours}h {totalMinutes}m
                        </h1>
                      )}
                    </>
                  )}
                </div>
               
                {/* Total idle time */}
                <div className="col-lg-3 col-md-12 col-sm-4 col-xs-12 header-dashboard-section py-3">
                  {memberLoading ? 
                  <div className="time-track-section">
                    <PulseLoader color="#36d7b7" />
                  </div> :
                  this.props.timeData === {} ? (
                    this.props?.companyList?.length === 0 ? (
                      <>
                        <span className="headline">Total time tracked</span>
                        <h1>0h 0m</h1>
                      </>
                    ) : (
                      <div className="time-track-section">
                        <PulseLoader color="#36d7b7" />
                      </div>
                    )
                  ) : (
                    <>
                      <span className="headline">Total idle time</span>
                      {this.props.timeData === undefined ? (
                        <h1>0h 0m</h1>
                      ) : isNaN(totalHours) ? (
                        // <div className="time-track-section">
                        //   <PulseLoader color="#36d7b7" />
                        // </div>
                          <h1>0h 0m</h1>
                      ) : (
                        <>
                          <h1>
                            {idleHours}h {idleMinutes}m
                          </h1>
                          <Row>
                            <div className="col-lg-4 col-md-4 col-sm-4">
                              {idleHoursPercent.toFixed(1)}%
                            </div>
                            <div className="col-lg-8 col-md-8 col-sm-8">
                              <div className="range_container">
                                <div
                                  className="range_percentage"
                                  style={{
                                    width: `${idleHoursPercent.toFixed(1)}%`,
                                  }}
                                >
                                  &nbsp;
                                </div>
                              </div>
                            </div>
                          </Row>
                        </>
                      )}
                    </>
                  )}
                </div>
                {/* Total productive time */}
                <div className="col-lg-3 col-md-12 col-sm-4 col-xs-12 pt-1 header-dashboard-section py-3">
                  {memberLoading ? 
                  <div className="time-track-section">
                    <PulseLoader color="#36d7b7" />
                  </div> :
                  this.props.timeData === {} ? (
                    this.props?.companyList?.length === 0 ? (
                      <>
                        <span className="headline">Total time tracked</span>
                        <h1>0h 0m</h1>
                      </>
                    ) : (
                      <div className="time-track-section">
                        <PulseLoader color="#36d7b7" />
                      </div>
                    )
                  ) : (
                    <>
                      <span className="headline">Total productive time</span>
                      {this.props.timeData === undefined ? (
                        <h1>0h 0m</h1>
                      ) : isNaN(totalHours) ? (
                        // <div className="time-track-section">
                        //   <PulseLoader color="#36d7b7" />
                        // </div>
                          <h1>0h 0m</h1>
                      ) : (
                        <>
                          <h1>
                            {productiveHours}h {productiveMinutes}m
                          </h1>
                          <Row>
                            <div className="col-lg-4 col-md-4 col-sm-4">
                              {productiveHoursPercent.toFixed(1)}%
                            </div>
                            <div className="col-lg-8 col-md-8 col-sm-8">
                              <div className="range_container">
                                <div
                                  className="range_percentage_green"
                                  style={{
                                    width: `${productiveHoursPercent.toFixed(
                                      1
                                    )}%`,
                                  }}
                                >
                                  &nbsp;
                                </div>
                              </div>
                            </div>
                          </Row>
                        </>
                      )}
                    </>
                  )}
                </div>

                <div className="col-lg-3 col-md-12 col-sm-4 col-xs-12 py-3">
                  {memberLoading ? 
                  <div className="time-track-section">
                    <PulseLoader color="#36d7b7" />
                  </div> :
                  this.props.timeData === {} ? (
                    this.props?.companyList?.length === 0 ? (
                      <>
                        <span className="headline">Total Earnings</span>
                        <h1>$0.0</h1>
                      </>
                    ) : (
                      <div className="time-track-section">
                        <PulseLoader color="#36d7b7" />
                      </div>
                    )
                  ) : (
                    <>
                      <span className="headline">Total Earnings</span>
                      
                      {this.props.timeData === undefined ? (
                        <h1>$0.0 </h1>
                        ) : isNaN(totalHours) ? (
                        // <div className="time-track-section">
                        //   <PulseLoader color="#36d7b7" />
                        // </div>
                          <h1>$0.0 </h1>
                      ) : (
                        <>
                        <h1>
                          ${((totalHours+(totalMinutes/60)) * (this.props?.rates ? this.props?.rates : 0 )).toFixed(2)}
                        </h1>
                      </>
                      )}
                    </>
                  )}
                </div>
              </Row>
              {/* </div> */}
            </Card>
            {/* </div> */}
            {/* </Row> */}
          </div>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  companyList: state.auth?.profile?.companyList,
  profile: state?.auth?.profile,
  memberLoading: state?.member?.memberLoading,
  days: state?.team?.days,
  timeData: state.member.memberTimeData,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(Overview);