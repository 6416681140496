import React, { useState } from "react";
import "./WeekWorkingHours.css";

const WeekWorkingHours = ({ data }) => {
  const [tooltip, setTooltip] = useState(null);

  const weekData = (() => {
    const newData = {};
    const temp = data?.forEach(([day, start, end]) => {
      newData[day] = [...(newData[day] || []), { start, end }];
    });
    return newData;
  })();

  const formatTime = (timeString) => {
    const date = new Date(timeString);
    return date.getHours() + date.getMinutes() / 60;
  };

  return (
    <div className="week-container">
      <div className="week-header">
        <div className="week-day"></div>
        <div className="week-sub-header">
          {["00", "06", "12", "18", "24"].map((time, index) => (
            <div key={index} className="week-time">
              {time}
            </div>
          ))}
        </div>
      </div>
      <div className="week-body">
        {Object.keys(weekData)?.map((day, index) => {
          const [dayName] = day.split(",");
          const dayData = weekData[day] || [];
          return (
            <div key={index} className="week-row">
              <div className="week-day">{dayName}</div>
              <div className="week-bar-container">
                {dayData?.map(({ start, end }, idx) => {
                  const startTime = formatTime(start);
                  const timeDiffInMs = new Date(end) - new Date(start);
                  const workingHours = timeDiffInMs / (1000 * 60 * 60);
                  const widthPercentage = (workingHours / 24) * 100;
                  const leftPercentage = (startTime / 24) * 100;
                  const tooltipData = {
                    day,
                    start: new Date(start).toLocaleTimeString().substring(0, 5),
                    end: new Date(end).toLocaleTimeString().substring(0, 5),
                    duration: workingHours.toFixed(2),
                    index
                  };
                  return (
                    <div
                      key={idx}
                      onMouseEnter={() => setTooltip(tooltipData)}
                      onMouseLeave={() => setTooltip(null)}
                      className="week-bar"
                      style={{
                        width: `${widthPercentage}%`,
                        left: `${leftPercentage}%`,
                      }}
                    ></div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      {tooltip && (
        <div className="week-tooltip" style={tooltip.index >= 3 ? {top: "10%"} : {bottom: "0%"}}>
          <p>
            <strong>{tooltip.day}</strong>
          </p>
          <hr />
          <p>
            {tooltip.start} - {tooltip.end}
          </p>
          <p>
            <strong>Duration:</strong> {tooltip.duration} hours
          </p>
        </div>
      )}
    </div>
  );
};

export default WeekWorkingHours;
