/* eslint-disable no-useless-constructor */
import React, { PureComponent } from "react";
import { Card, Container, Row, Button, Col } from "reactstrap";
import { connect } from "react-redux";
import Popover from "@material-ui/core/Popover";
import "../../assets/scss/argon-dashboard/custom/body-container/_body-container.scss";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Trends from "../Trends/index";
import Topusers from "../Topusers";
import PulseLoader from "react-spinners/PulseLoader";
import "../../assets/css/nousers.css";
import ProjectTable from "../../views/Dashboard/projectDetailTable";
am4core.useTheme(am4themes_animated);
class Header extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      setAnchorEl: null,
    };
  }

  totalIdleTime = (event) => {
    this.setState({
      setAnchorEl: event.currentTarget,
    });
  };
  productiveTime = (event) => {
    this.setState({
      setAnchorEl: event.currentTarget,
    });
  };
  topProjects = (event) => {
    this.setState({
      setAnchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      setAnchorEl: null,
    });
  };

  noTimeTracked = (users) => {
    let membersId = [];
    if (users && users?.length > 0) {
      console.log(users , "-------");
      return users?.map((arrVal) => {
        // eslint-disable-next-line array-callback-return

        return (
          <Row className="table-border">
            <div className="col-6 mobile-view">{arrVal.userName}</div>
            <div className="col-5 col-md-4 mobile-view">Never tracked time</div>
            {/* <div className=" col-3">
              <div className="hide_from_reports">
                <VisibilityOffIcon style={{ fontSize: 12 }} /> Hide from reports
              </div>
            </div> */}
          </Row>
        );

        // };
      });
      // }
    } else {
      return <h1>No Users</h1>;
    }
  };

  render() {
    const open = Boolean(this.state.setAnchorEl);
    const id = open ? "simple-popover" : undefined;
    let teamLoading = this.props?.teamLoading;
    let totalHours = this.props?.teamTimeData?.totalHours;
    let totalMinutes = this.props?.teamTimeData?.totalMinutes;

    let idleHours = this.props?.teamTimeData?.idleHours;
    let idleMinutes = this.props?.teamTimeData?.idleMinutes;

    const renderTimeObjDetails = {
      totalTimetrack: "0h 0m",
      totalUnproductiveTime: "0h 0m",
    };

    let percentage = 0;
    let totalminutes = 0;
    const totalMinutesCount =
      this.props?.topProjects &&
      this.props?.topProjects
        .sort((a, b) => (a.time < b.time ? 1 : -1))
        .slice(0, 5)
        .forEach((e) => {
          totalminutes += e.hours * 60 + e.minuites;
        });
    const topProjectsData =
      this.props.topProjects &&
      this.props.topProjects
        .sort((a, b) => (a.time < b.time ? 1 : -1))
        .slice(0, 5)
        .map((p) => {
          percentage = `${(
            ((p.hours * 60 + p.minuites) * 100) /
            totalminutes
          ).toFixed(1)}%`;
          return {
            hours: p.hours,
            minuites: p.minuites,
            projectName:
              this?.props?.projects?.length > 0 &&
              this.props?.projects?.find((item) => item?.id === p?.projectName)
                ?.data?.project_name,
            percentageData: percentage,
            time: `${p.hours * 12 + p.minuites}`,
          };
        });

    console.log(this.props.topProjects, "topProjects in Header");
    console.log(topProjectsData, "topProjectsData in Header");
    console.log(this.props.teamTimeData, "teamTimeData in Header");
    // Add data
    var chart = am4core.create("overview_top_projects", am4charts.PieChart);
    chart.data = topProjectsData;

    // Add and configure Series
    var pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "percentageData";
    pieSeries.dataFields.category = "projectName";
    pieSeries.innerRadius = am4core.percent(50);
    pieSeries.ticks.template.disabled = true;
    pieSeries.labels.template.disabled = true;
    pieSeries.slices.template.tooltipText =
      "{projectName}: {percentageData} ({hours}.{minuites} hrs)";

    pieSeries.colors.list = [
      am4core.color("green"),
      am4core.color("red"),
      am4core.color("#FF6F91"),
      am4core.color("#FF9671"),
      am4core.color("#FFC75F"),
      am4core.color("#F9F871"),
    ];

    let totalMinutesToHours = totalMinutes / 60;
    let idleMinutesToHours = idleMinutes / 60;
    totalHours += Math.trunc(totalMinutesToHours);
    totalMinutes = totalMinutes % 60;

    idleHours += Math.trunc(idleMinutesToHours);
    idleMinutes = idleMinutes % 60;

    let totalHoursToMinutes = totalHours * 60 + totalMinutes;
    let idleHoursToMinutes = idleHours * 60 + idleMinutes;

    let productiveHours = totalHours - idleHours;
    let productiveMinutes = totalMinutes - idleMinutes;
    if (productiveMinutes < 0) {
      productiveMinutes = 60 + productiveMinutes;
      productiveHours -= 1;
    }
    let productiveHoursToMinutes = productiveHours * 60 + productiveMinutes;

    let productiveMinutesToHours = productiveMinutes / 60;
    productiveHours += Math.trunc(productiveMinutesToHours);
    productiveMinutes = productiveMinutes % 60;

    let productiveHoursPercent = 0;
    let idleHoursPercent = 0;
    if (productiveHoursToMinutes !== 0) {
      productiveHoursPercent =
        (productiveHoursToMinutes / totalHoursToMinutes) * 100;
    }
    if (idleHoursToMinutes !== 0) {
      idleHoursPercent = (idleHoursToMinutes / totalHoursToMinutes) * 100;
    }

    if (totalHours === isNaN) {
      window.location.reload();
    }
    return (
      <>
        <div
          className="header pt-md-0"
          style={{ paddingTop: "5rem!important" }}
        >
          <Container fluid>
            <div className="header-body">
              <Row>
                <div className="col dashboard-section-heading">
                  <h3>Overview</h3>
                </div>
              </Row>
              {/* <Row> */}
              {/* <div className="row col-12 header-dashboard"> */}
              <Card className="header pt-md-12 shadow chart-card">
                {/* <div style={{ height: "auto" }} className="p--1"> */}
                <Row className="card-min-hight">
                  {/* Total time Tracked */}
                  <div className="col-lg-4 col-md-12 col-sm-4 col-xs-12 header-dashboard-section">
                    {this.props.teamTimeData &&
                    teamLoading ? (
                      this.props?.companyList?.length === 0 ? (
                        <>
                          <span className="headline">Total time tracked</span>
                          <h1>0h 0m</h1>
                        </>
                      ) : (
                        <div className="time-track-section">
                          <PulseLoader color="#36d7b7" />
                        </div>
                      )
                    ) : (
                      <>
                        <span className="headline">Total time tracked</span>
                        {this.props.teamTimeData ? (
                          this.props.teamTimeData.totalHours === undefined ? (
                            <h1>0h 0m</h1>
                          ) : isNaN(totalHours) ? (
                            <h1>0h 0m</h1>
                          ) : (
                            <h1>
                              {totalHours}h {totalMinutes}m
                            </h1>
                          )
                        ) : (
                          <h1>0h 0m</h1>
                        )}
                      </>
                    )}
                  </div>
                  {/* Total idle time */}
                  <div className="col-lg-4 col-md-12 col-sm-4 col-xs-12 header-dashboard-section">
                    {this.props.teamTimeData &&
                    teamLoading ? (
                      this.props?.companyList?.length === 0 ? (
                        <>
                          <span className="headline">Total idle time</span>
                          <h1>0h 0m</h1>
                        </>
                      ) : (
                        <div className="time-track-section">
                          <PulseLoader color="#36d7b7" />
                        </div>
                      )
                    ) : (
                      <>
                        <span className="headline">Total idle time</span>
                        {this.props.teamTimeData ? (
                          this.props.teamTimeData.idleHours === undefined ? (
                            <h1>0h 0m</h1>
                          ) : isNaN(idleHours) ? (
                            <h1>0h 0m</h1>
                          ) : (
                            <>
                              <h1>
                                {idleHours}h {idleMinutes}m
                              </h1>
                              <Row>
                                <div className="col-lg-6 col-md-4 col-sm-6">
                                  {idleHoursPercent.toFixed(1)}%
                                </div>
                                <div className="col-lg-8 col-md-8 col-sm-8">
                                  <div className="range_container">
                                    <div
                                      className="range_percentage"
                                      style={{
                                        width: `${idleHoursPercent.toFixed(
                                          1
                                        )}%`,
                                      }}
                                    >
                                      &nbsp;
                                    </div>
                                  </div>
                                </div>
                              </Row>
                            </>
                          )
                        ) : (
                          <h1>0h 0m</h1>
                        )}
                      </>
                    )}
                  </div>
                  {/* Total productive time */}
                  <div className="col-lg-4 col-md-12 col-sm-4 col-xs-12 pt-1">
                    {this.props.teamTimeData &&
                    teamLoading ? (
                      this.props?.companyList?.length === 0 ? (
                        <>
                          <span className="headline">
                            Total productive time
                          </span>
                          <h1>0h 0m</h1>
                        </>
                      ) : (
                        <div className="time-track-section">
                          <PulseLoader color="#36d7b7" />
                        </div>
                      )
                    ) : (
                      <>
                        <span className="headline">Total productive time</span>
                        {this.props.teamTimeData ? (
                          this.props.teamTimeData.productiveHours ===
                          undefined ? (
                            <h1>0h 0m</h1>
                          ) : isNaN(productiveHours) ? (
                            <h1>0h 0m</h1>
                          ) : (
                            <>
                              <h1>
                                {productiveHours}h {productiveMinutes}m
                              </h1>
                              <Row>
                                <div className="col-lg-6 col-md-4 col-sm-6">
                                  {productiveHoursPercent.toFixed(1)}%
                                </div>
                                <div className="col-lg-8 col-md-8 col-sm-8">
                                  <div className="range_container">
                                    <div
                                      className="range_percentage_green"
                                      style={{
                                        width: `${productiveHoursPercent.toFixed(
                                          1
                                        )}%`,
                                      }}
                                    >
                                      &nbsp;
                                    </div>
                                  </div>
                                </div>
                              </Row>
                            </>
                          )
                        ) : (
                          <h1>0h 0m</h1>
                        )}
                      </>
                    )}
                  </div>
                </Row>
                {/* </div> */}
              </Card>
              {/* </div> */}
              {/* </Row> */}
            </div>
          </Container>
          <Container className="mt-3" fluid>
            <Row>
              <div className="mb-2 col-sm-12 col-md-12 col-lg-6 pr-lg-1">
                <Card className="shadow p-3">
                  <div className="p--1 topProjectsCard">
                    <Row>
                      <div className="col">
                        <span className="headline">Top 5 projects</span>
                      </div>
                    </Row>
                    <Row
                      className="dashboard-section-body scroll screensm"
                      style={{ paddingTop: 0 }}
                    >
                      <Col md={12} lg={12}>
                        <Container className="mt-2 card-table-container">
                          <ProjectTable
                            data={this?.props?.topProjects}
                            projects={this?.props?.projects}
                            type="card"
                          />
                        </Container>

                        {/* {this?.props?.topProjects?.length > 0 &&
                          this?.props?.topProjects
                            ?.sort((a, b) => (a.time < b.time ? 1 : -1))
                            ?.slice(0, 5)
                            .map((p, i) => {
                              return (
                                <>
                                  <Col
                                    md={12}
                                    className="d-flex justify-content-between"
                                  >
                                    <div
                                      className="circular inline-block"
                                      style={{
                                        marginTop:"4px",
                                        background: `
                                        ${pieSeries.colors.list[i]}`,
                                      }}
                                    ></div>
                                    <div className="dashboard-section-text inline-block">
                                      {
                                        (
                                          this?.props?.projects?.length > 0 &&
                                          this?.props?.projects?.find(
                                            (item) => item.id === p?.projectName
                                          )
                                        ).data?.project_name
                                      }
                                    </div>
                                    <h4>
                                      {p?.hours}h {p?.minuites}m
                                    </h4>
                                  </Col>
                                </>
                              );
                            })} */}
                      </Col>
                      {/* <div className="col">
                        <div id="overview_top_projects"></div>
                      </div> */}
                    </Row>
                  </div>
                </Card>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6">
                <Card className="shadow p-3">
                  <div className="col-12 topProjectsCard">
                    <Row>
                      <div className="col">
                        <span className="headline">
                          {"Haven't tracked time yet"}
                        </span>
                      </div>
                    </Row>
                    <Row className="dashboard-section-body nousers scroll screensm">
                      {this.noTimeTracked(this.props?.untrackedUsers)}
                    </Row>
                  </div>
                </Card>
              </div>
            </Row>
          </Container>
          <Trends
            teamLogsTrends={renderTimeObjDetails}
            weekData={this.props.weekData}
          />
          <Topusers topUsers={this.props.topData} />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  teamLoading: state.team.teamLoading,
  teamLogs: state.team.teamLogs,
  timeData: state.team.timeData,
  weekData: state.team.weekData,
  topData: state.team.topData,
  membersData: state.team.membersData,
  companyList: state.auth?.profile?.companyList,
  teamTimeData: state.team.teamTimeData,
  topProjects: state?.team?.topProjects,
  untrackedUsers: state?.team?.untrackedUsers,
  projects: state.team.projects,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
