import firebase from "../config";
const database = firebase.firestore();

async function uploadImage(image, fileName) {
  try {
    // const url = `https://us-central1-workfh-b2cee.cloudfunctions.net/uploadImage`;
    const url = `http://127.0.0.1:5001/workfh-b2cee/us-central1/uploadImage`;
    const headers = new Headers();
    headers.append("Content-Type", "application/json;charset=utf-8");
    headers.append("Access-Control-Allow-Origin", "*");
    console.log({image,fileName});
    return await fetch(url, {
      headers,
      method: "POST",
      body: JSON.stringify({
        image,
        fileName,
      }),
    })
      .then((r) => r.json())
      .then((res) => {
        console.log("data in uploadImage", res.result);
        let data;
        if (res.success) {
          data = res.result;
          console.log("data in success", data);
          return {
            success: true,
            data: res.result,
          };
        } else {
          return {
            success: false,
            data: res.message,
          };
        }
      })
      .catch((error) => {
        return {
          success: false,
          data: error,
        };
      });
  } catch (err) {
    console.log(err);
  }
}

const createPOC = async (payload={}) => {
    try {
        console.log({payload});
        const {
            totalTimeTracked,
            timeTracked,
            applicationName,
            imageList = []
        } = payload;
      const userToken = 'test-token-0000';
    // const url = `https://us-central1-workfh-b2cee.cloudfunctions.net/createPOC`;
      const url = `http://127.0.0.1:5001/workfh-b2cee/us-central1/createPOC`;
      const headers = new Headers();
      headers.append("Access-Control-Allow-Origin", "*");
      const formData = new FormData();
      formData.append('totalTimeTracked',totalTimeTracked);
      formData.append('timeTracked',timeTracked);
      formData.append('applicationName',applicationName);
      formData.append('userToken',userToken);
      if(imageList.length > 0){
        [...imageList].forEach(file=>formData.append('files',file));
      }
      const res = await fetch(url,{
        headers,
        method: "POST",
        body: formData
      });
      const data = await res.json();
      console.log({data})
    } catch (error) {
      console.log(error)
    }
}

function getPOCData(payload={}){
  const {
    userId, 
    teamId,
    userToken,
    companyId = "wal3xRi8taICeQZN235Z",
    formattedDate
  } = payload;
  try {
    console.log("companyId=====>",companyId)
    return database
      .collection("variant2_WfhGO_DB")
      .doc(companyId)
      .collection("members")
      .doc(userToken)
      .collection("days")
      .doc(formattedDate)
      .get()
      .then((response) => {
        if (response) {
          const docData = response.data();
          return {
            success: true,
            data: docData?.calculatedTimelog ? docData?.calculatedTimelog : {},
          };
        } else {
          return {
            success: false,
            data: {},
            message: "Document does not exist",
          };
        }
      })
      .catch((error) => {
        console.error(error);
        return {
          success: false,
          data: error,
        };
      });
  } catch (error) {
    console.error(error);
    return {
      success: false,
      data: error,
    };
  }
}

async function getApplicationInfo (){
  try {
    const applicationInfoRef = database.collection("applicationInfo");
    const applicationInfoData = await applicationInfoRef.get();
    let data = {};
    applicationInfoData.docs.forEach((doc)=>{
      const docData = doc.data();
      if(docData) data = { ...data, ...docData };
    })
    return {
      success: true,
      data
    };
  } catch (error) {
    console.error(error);
    return {
      success: false,
      data: error,
    };
  }
}

export default {
  uploadImage,
  createPOC,
  getPOCData,
  getApplicationInfo
};
